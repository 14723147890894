import type { AppEnvironment } from "shared/SharedTypes";

export const APP_ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT as AppEnvironment;
export const APP_ORIGIN = process.env.NEXT_PUBLIC_ORIGIN;
export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;
export const IS_MAIN = APP_ENVIRONMENT === "main";

export const CONTACT_EMAIL = "contact@greenlinks.app";

export const ENABLE_FACEBOOK_LOGIN = false;
export const ENABLE_ADSENSE = false;

export const GOOGLE_ADSENSE_ACCOUNT = "ca-pub-3606863258246580";

const BASE_URLS: any = {
  main: "https://api.greenlinks.app/v1",
  dev: "https://api.dev.greenlinks.app/v1",
  local: `http://${typeof window === "undefined" ? "localhost" : window.location.hostname}:5100/v1`,
};
export const BACKEND_URL = BASE_URLS[APP_ENVIRONMENT || "local"];
export const TRACING_ORIGIN_REGEX = new RegExp(`^${BACKEND_URL}`);

const SENTRY_DSNS: any = {
  main: "https://c83fe1383cefde341e2554854ece282a@o4507321787547648.ingest.us.sentry.io/4507828330364928",
  dev: "https://5f2aa8fe44fcb9e61dc2c1bb7cf1f928@o4507321787547648.ingest.us.sentry.io/4507827200786432",
};
export const SENTRY_DSN = SENTRY_DSNS[APP_ENVIRONMENT || "local"];
